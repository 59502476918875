import * as R from 'ramda'
import React from 'react'
import { graphql } from 'gatsby'
import Slider from 'react-slick'
import HeroSlider from '../components/HeroSlider'
import { isMobile } from 'react-device-detect'

import { createMarkup, mapIndexed } from '../lib/Helpers'
import BlockContent from '../components/BlockContent'
import Layout from '../components/layout'
import CountTo from 'react-count-to'
import config from '../../data/SiteConfig'

class IndexPage extends React.Component {
  render() {
    const page = this.props.data.sanityHomePage
    const settings = {
      autoplay: true,
      autoplaySpeed: 10000,
      arrows: false,
      dots: true,
      infinite: true,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
    }
    return (
      <Layout slug={page.slug}>
        {/* Banner */}
        <section id="banner" className="page page--home">
          {isMobile ?
            <HeroSlider />
          :
            <>
              <div className="content">
                <header className="major special hero">
                  <h1>
                    {page.hero_title} <br /> {page.hero_subtitle}
                  </h1>
                  <h3>Simply the Best for your business</h3>
                </header>
              </div>
              <div
                style={{
                  backgroundImage: `url(${require('../images/hero-slider/001-slideshow.jpg')})`,
                  backgroundSize: 'cover',
                }}
                className="hero__img-bg"
              />
              <video
                poster={require('../images/hero-slider/001-slideshow.jpg')}
                id="bgvid"
                playsInline=""
                autoPlay={true}
                muted={true}
                loop={true}
                allowFullScreen
                className="hero__video-bg"
              >
                <source
                  src={require('../assets/cutabove-2021-sm.mp4')}
                  type="video/webm"
                />
              </video>
            </>
          }
        </section>

        {/* Section */}
        <section className="wrapper style1" id="first">
          <div className="inner">
            <header className="major special">
              <div>
                <h2>{page._rawIntroContent.title}</h2>
                <BlockContent blocks={page._rawIntroContent.body} />
              </div>
            </header>
            <div className="separator--50" />
            <div className="row gtr-200">
              <div className="col-centered col-6 col-12-medium">
                <div className="box text-centered">
                  <h2>
                    <i className="icon alt custom ca-trim major" />
                    <CountTo delay={5} to={60000000} speed={10000} /> +
                  </h2>
                  <h4>Grams Trimmed</h4>
                </div>
              </div>
              <div className="col-6 col-centered col-12-medium">
                <div className="box text-centered">
                  <h2>
                    <i className="icon alt custom ca-careers major" />
                    <CountTo delay={10} to={500} speed={10000} /> +
                  </h2>
                  <h4>Jobs Found</h4>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Section */}
        <section className="wrapper style2 alt">
          <div className="inner">
            <header className="major special">
              <h2>{page.features_title}</h2>
              <p>{page.features_subtitle}</p>
            </header>
            <div className="features">
              <ul>
                {mapIndexed((item, index) => (
                  <li key={index}>
                    <img src={item.icon.asset.url} style={{background: 'white', border: '1px solid white', borderRadius: '50%', height: 84, padding: 10}} />
                    <h3>{item.title}</h3>
                    <p>{item.content}</p>
                  </li>
                ))(R.slice(0, 2, page.features_list))}
              </ul>
              <span className="image">
                <img
                  src={page.features_image.asset.url}
                  alt=""
                />
              </span>
              <ul>
                {mapIndexed((item, index) => (
                  <li key={index}>
                    <img src={item.icon.asset.url} style={{background: 'white', border: '1px solid white', borderRadius: '50%', height: 84, padding: 10}} />
                    <h3>{item.title}</h3>
                    <p>{item.content}</p>
                  </li>
                ))(R.slice(2, 4, page.features_list))}
              </ul>
            </div>
          </div>
        </section>

        {/* Section */}
        <section className="wrapper style1">
          <div className="inner">
            <header className="major special alt">
              <h2>Testimonials</h2>
            </header>
            <Slider {...settings}>
              {mapIndexed((item, index) => (
                <header className="major special alt" key={index}>
                  <BlockContent blocks={item._rawBody} />
                  <h3>{item.name}</h3>
                </header>
              ))(page.testimonials)}
            </Slider>
          </div>
        </section>
      </Layout>
    )
  }
}

export default IndexPage

export const HomeQuery = graphql`
  query {
    sanityHomePage {
      _rawIntroContent
      counter {
       icon {
         asset {
          url
         }
       }
       count_to
       title
      }
      hero_title
      hero_subtitle
      hero_slider_images {
        asset {
          url
        }
      }
      features_list {
        title
        content
        icon {
          asset {
            url
          }
        }
      }
      features_title
      features_subtitle
      features_image {
        asset {
          url
        }
      }
      testimonials {
        _rawBody
        name
      }
    }
  }
`

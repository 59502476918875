import * as R from 'ramda'
import React from 'react'
import Slider from 'react-slick'
import { mapIndexed, notNilOrEmpty } from '../lib/Helpers'

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}


export default () => {
  let images
  const [imgsArr, setImgsArr] = React.useState([])

  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    dots: true,
    fade: true,
    infinite: true,
    lazyLoad: true,
    swipe: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  React.useEffect( () => {
    _getAllImages()
  }, [])

  function _getAllImages() {
    images = importAll(require.context('../images/hero-slider/', false, /\.(png|jpe?g|svg)$/));
    R.forEachObjIndexed(e => setImgsArr(prev => R.append(e, prev)), images)
  }
  return(
    <section className="hero-slider">
      <Slider {...settings}>
        {
          notNilOrEmpty(imgsArr) &&
          mapIndexed( (el, i) => {
            return(
              <div className="content container" key={i}>
                <div style={{
                  background: `url(${el}) center / cover no-repeat`,
                }} className="hero-slider--background-img">
                  <div className="overlay" />
                  <header className="major special hero">
                    <h1>
                      The Premier Licensed Cannabis Staffing <br /> Agency in Nevada
                    </h1>
                    <h3>Simply the Best for your business</h3>
                  </header>
                </div>
              </div>
            )
          })(imgsArr)
        }
      </Slider>
    </section>
  )
}
